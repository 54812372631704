import * as React from "react"
import {useState} from "react"
import "../styles/style.scss"
import Seo from "../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import scrollTo from 'gatsby-plugin-smoothscroll';
import Slider from "react-slick";
import {graphql} from "gatsby"


export default function IndexPage(data) {
    const today = new Date().getFullYear();
    const birth = new Date().getFullYear() - 2017;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerPadding: "70px",
        centerMode: true,
        className: "slick",
        pauseOnHover: true,
        cssEase: "ease-in-out",
        appendDots: dots => <span className={"dots"}>{dots}</span>,
        responsive: [{
            breakpoint: 1024, settings: {
                dots: false
            }
        }]
    };

    const videos = data.data.allVimeoVideo.nodes;

    const [openMenu, setOpenMenu] = useState(false);

    return (<body className={"idprod"}>
    <a className={"anchor"} href={"/"} id='accueil'></a>
    <Seo title={"iDprod - le plus intense de l'audiovisuel local"}/>
    <header>
        <nav className="navbar is-fixed-top is-dark" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <div className={"level"}>
                    <div className="level-item has-text-centered">
                        <a className={"logo"}>
                            <StaticImage src="../images/logo_dark.png" width={200} alt={"logo iDprod"}/>
                        </a>
                    </div>
                    <div className={"level is-mobile contacts"}>
                        <div className="level-item has-text-centered">
                            <a target="_blank" rel="noopener noreferrer" href={"tel:+262692408772"}
                               className="button is-square is-danger"><span
                                className="icon"><i
                                className="fa fa-phone-alt"></i></span></a>
                        </div>
                        <div className="level-item has-text-centered">
                            <a target="_blank" rel="noopener noreferrer" href={"mailto:contact@idprod.re"}
                               className="button is-square is-danger"><span className="icon"><i
                                className="fa fa-envelope"></i></span></a>
                        </div>
                        <div className="level-item has-text-centered">
                            <a target="_blank" rel="noopener noreferrer" href="http://m.me/idprod.re" target="_blank"
                               rel="noopener noreferrer"
                               className="button is-square is-danger"><span className="icon"><i
                                className="fab fa-facebook-messenger fa-lg"></i></span></a>
                        </div>
                    </div>
                </div>
                <a role="button" onClick={() => setOpenMenu(!openMenu)}
                   className={`navbar-burger burger${openMenu ? ' is-active' : ''}`} aria-label="menu"
                   aria-expanded="false"
                   data-target="navigation">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navigation" className={`navbar-menu${openMenu ? ' is-active' : ''}`}>
                <div className="navbar-end">
                    <a onClick={() => scrollTo('#accueil')} className="navbar-item">ACCUEIL</a>
                    <a onClick={() => scrollTo('#presentation')} className="navbar-item">À PROPOS</a>
                    <a onClick={() => scrollTo('#portfolio')} className="navbar-item">PORTFOLIO</a>
                    <a onClick={() => scrollTo('#contact')} className="navbar-item">CONTACT</a>
                </div>
            </div>
        </nav>
    </header>

    <section className="section accueil">
        <div className="container">
            <Slider {...settings}>
                {
                    videos.map(video => {
                        return <div>
                            <a href={video.link} target="_blank" rel="noopener noreferrer">
                                <img className={"slide slider_image"} src={video.thumbnail} alt={"slider4 1"}/>
                                <div className={"slider_title"}>
                                    <div>{video.name}</div>
                                    {/*<br/>*/}
                                    {/*<div><span className="tag is-link is-light">#Link</span></div>*/}
                                </div>
                            </a>
                        </div>
                    })
                }
            </Slider>
        </div>
    </section>

    <a className={"anchor"} id='presentation'></a>
    <section className="section presentation">
        <h1 className="title is-2 has-text-centered has-text-white is-uppercase chapter">
            <ul>
                <li>À propos</li>
            </ul>
        </h1>
        <div className="container">
            <hr/>
            <br/>
            <div className="columns">
                <div className="column has-text-justified presentation_text">
                    <p className="has-text-light is-size-3-desktop is-size-4 has-text-justified">
                        Installée depuis plus de {birth} années,
                    </p><br/>
                    <p className="has-text-light is-size-3-desktop is-size-4 has-text-justified">
                        <span className={"logo_id"}>iD</span>prod est une société de production qui incarne un
                        savoir-faire à la pointe de la création et
                        de la diffusion de communications à usage interne et externe.
                    </p><br/>
                    <p className="has-text-light is-size-3-desktop is-size-4 has-text-justified">
                        À destination des sociétés privées (agences de communication, télévision, ...)
                        et des institutions (communes, départements, ...) qui souhaitent:
                    </p><br/>
                    <div className="container">
                        <div className="columns is-multiline">
                            <div className="column is-4">
                                <div className="card hover-shadow shadow service">
                                    <div className="card-content has-text-centered">
                                        <i className="ti-palette icon mb-10 is-inline-block"></i>
                                        <h4 className="mb-20 title is-4">
                                            <ul>
                                                <li>Direct Live</li>
                                            </ul>
                                        </h4>
                                        <p className={"is-size-5"}>Couvrir un évènement en direct sur les réseaux
                                            sociaux</p>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="card hover-shadow shadow service">
                                    <div className="card-content has-text-centered">
                                        <i className="ti-palette icon mb-10 is-inline-block"></i>
                                        <h4 className="mb-20 title is-4">
                                            <ul>
                                                <li>Film multimédia</li>
                                            </ul>
                                        </h4>
                                        <p className={"is-size-5"}>Créer et diffuser du contenu multimédia</p>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="card hover-shadow shadow service">
                                    <div className="card-content has-text-centered">
                                        <i className="ti-palette icon mb-10 is-inline-block"></i>
                                        <h4 className="mb-20 title is-4">
                                            <ul>
                                                <li>Film documentaire</li>
                                            </ul>
                                        </h4>
                                        <p className={"is-size-5"}>Produire un documentaire ou une émission</p>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="card hover-shadow shadow service">
                                    <div className="card-content has-text-centered">
                                        <i className="ti-palette icon mb-10 is-inline-block"></i>
                                        <h4 className="mb-20 title is-4">
                                            <ul>
                                                <li>Consulting</li>
                                            </ul>
                                        </h4>
                                        <p className={"is-size-5"}>Se faire accompagner dans un projet de
                                            communication</p>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <a href={"https://cineloc.re"}
                                   target="_blank" rel="noopener noreferrer">
                                    <div className="card hover-shadow shadow service cineloc">
                                        <div className="card-content has-text-centered">
                                            <i className="ti-palette icon mb-10 is-inline-block"></i>
                                            <h4 className="mb-20 title is-4">
                                                <ul>
                                                    <li>Location de matériel</li>
                                                </ul>
                                            </h4>
                                            <p className={"is-size-5"}>Disposer d'une salle de montage ou de
                                                matériel
                                                avec</p>
                                            <br/>

                                            <figure className="image is-64x64">
                                                <StaticImage src="../images/cineloc.png"
                                                             alt="cineloc"/>
                                            </figure>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <p className="has-text-light is-size-3-desktop is-size-4 has-text-justified">
                        La Réunion possède une diversité de paysages exceptionnels et <span
                        className={"logo_id"}>iD</span>prod est unique par sa capacité
                        à vous accompagner <span
                        className={"is-italic"}>"dans les airs, sur le terrain et sous la mer"</span>.
                    </p>
                    <br/>
                    <p className="has-text-light is-size-3-desktop is-size-4 has-text-justified">
                        Faire appel à <span className={"logo_id"}>iD</span>prod c'est la garantie de sublimer vos
                        communications pour atteindre efficacement votre public.
                    </p>
                    <br/>
                </div>
            </div>
        </div>
        <div className="list list-hero-social">
            <a className="list-item has-text-white"><i className="ti-facebook"></i></a>
            <a className="list-item has-text-white"><i className="ti-instagram"></i></a>
            <a className="list-item has-text-white"><i className="ti-dribbble"></i></a>
            <a className="list-item has-text-white"><i className="ti-twitter"></i></a>
        </div>
    </section>

    <a className={"anchor"} id='portfolio'></a>
    <section className="section portfolio">
        <h1 className="title is-2 has-text-centered is-uppercase has-text-white chapter">
            <ul>
                <li>Portfolio</li>
            </ul>
        </h1>
        <div className="container">
            <hr/>
            <br/>
            <div className="columns is-multiline shuffle-wrapper">
                {
                    videos.map(video => {
                        return <div className="column is-6 shuffle-item video">
                            <div className="card">
                                <div className="card-content">
                                    <iframe src={video.url} width="100%"
                                            height="360" allow="autoplay; fullscreen; picture-in-picture"
                                            allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </section>

    <a className={"anchor"} id='contact'></a>
    <section className="section">
        <h1 className="title is-2 has-text-centered is-uppercase has-text-white chapter">
            <ul>
                <li>Contacts</li>
            </ul>
        </h1>
        <div className="container">
            <hr/>
            <br/>
            <div className={"level is-mobile"}>
                <div className="level-item has-text-centered">
                    <a target="_blank" rel="noopener noreferrer" href={"tel:+262692408772"}
                       className="button is-square is-large is-danger"><span
                        className="icon"><i
                        className="fa fa-phone-alt"></i></span></a>
                </div>
                <div className="level-item has-text-centered">
                    <a target="_blank" rel="noopener noreferrer" href={"mailto:contact@idprod.re"}
                       className="button is-square is-large is-danger"><span className="icon"><i
                        className="fa fa-envelope"></i></span></a>
                </div>
                <div className="level-item has-text-centered">
                    <a target="_blank" rel="noopener noreferrer" href="http://m.me/idprod.re" target="_blank"
                       rel="noopener noreferrer"
                       className="button is-square is-large is-danger"><span className="icon"><i
                        className="fab fa-facebook-messenger fa-lg"></i></span></a>
                </div>
                <div className="level-item has-text-centered">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/idprod_re"
                       className="button is-square is-large is-danger"><span className="icon"><i
                        className="fab fa-instagram fa-lg"></i></span></a>
                </div>
                <div className="level-item has-text-centered">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@idprod"
                       className="button is-square is-large is-danger"><span className="icon"><i
                        className="fab fa-tiktok fa-lg"></i></span></a>
                </div>
            </div>
        </div>
    </section>

    <footer className="footer-section">
        <div className="container">
            <hr/>
            <br/>
            <div className={"copyrights has-text-centered has-text-white title is-4"}>
                <span>© {today} - <span className={"logo_id"}>iD</span>prod.re</span>
            </div>
        </div>
    </footer>
    </body>)
}

    export const query = graphql`
 query VimeoQuery {
  allVimeoVideo {
    nodes {
      name
      url
      description
      thumbnail
      link
    }
  }
}
`
