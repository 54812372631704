import * as React from "react"
import {Helmet} from "react-helmet";
import {graphql, useStaticQuery} from "gatsby";

export default function Seo({description, language, meta = {}, title}) {
    const {site} = useStaticQuery(
        graphql`{
            site {
                siteMetadata {
                    title
                    description
                    author
                    keywords
                    lang
                }
            }
        }`
    )

    const metaDescription = site.siteMetadata?.description || description;
    const defaultTitle = site.siteMetadata?.title || title;
    const keywords = site.siteMetadata?.keywords?.join(",") || [];
    const author = site.siteMetadata?.author;
    const lang = site.siteMetadata?.lang || language;

    return <Helmet
        htmlAttributes={{lang}}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
            {
                name: `description`,
                content: metaDescription,
            },
            {
                name: "keywords",
                content: keywords,
            },
            {
                property: `og:title`,
                content: title,
            },
            {
                property: `og:description`,
                content: metaDescription,
            },
            {
                property: `og:type`,
                content: `website`,
            },
            {
                name: `twitter:card`,
                content: `summary`,
            },
            {
                name: `twitter:creator`,
                content: author,
            },
            {
                name: `twitter:title`,
                content: title,
            },
            {
                name: `twitter:description`,
                content: metaDescription,
            },
            {
                name: "google-site-verification",
                content: "rdHgeMNE19n12zlkjsfSKFLDS23MuTFSHl8lQPesFHLk21oU",
            }
        ].concat(meta)}>
        <script src="https://kit.fontawesome.com/b18ef820ba.js" crossOrigin="anonymous"/>
    </Helmet>
}
